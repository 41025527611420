<template>

    <div class="consignment-notes">

        <div class="page-title d-flex py-2 justify-content-between">

            <h1 class="h4 mb-0">Перечень товарных накладных по подряду</h1>

            <div class="d-flex align-items-center">
                <BButton
                    class="ml-3"
                    variant="outline-primary"
                    v-b-modal.import>Импортировать
                </BButton>
                <BButton
                    class="ml-3"
                    variant="primary"
                    to="/create-consignment-note"
                >Создать
                </BButton>
            </div>

        </div>

        <TableFilter
            :columns="consignmentNotesFields"
            @update-selected-columns="columns = $event"
            @search="filter = $event"
            @clear="clearFilterData"
            @open-filter-modal="$root.$emit('bv::toggle::collapse', 'filter')"
        />

        <DefaultTable
            :data="consignmentNotes"
            :fields="consignmentNotesFieldsFiltered"
            :filter="filter"
            :loading="loading"
            rowLink="/consignment-notes"
            emptyText="Нет товарных накладных"
            :noLocalSorting="true"
            @sort-change="sorting"
            @pagination-change="paginationChange"
            @per-page-change="perPageChange"
        />

        <FilterSidebar
            id="filter"
            v-model="filterData"
            @apply="updateDataWithFilters(true)"
        />

        <b-modal
            id="import"
            centered title="Импорт товарных накладных по подряду">

            <div class="p-2 bg-light">

                <div class="font-weight-bold text-muted">Файл для импорта <span class="text-danger">*</span></div>

                <b-form-file
                    v-model="importFile"
                    class="mt-1"
                    plain />

            </div>

            <template #modal-footer="{ hide }">
                <b-button variant="outline-primary" @click="hide()">
                    Отмена
                </b-button>
                <b-button
                    variant="primary"
                    @click="sendImport">
                    Импортировать
                </b-button>
            </template>
        </b-modal>

        <b-modal
            id="import-success"
            centered title="Импорт завершён">

            <div class="p-2 bg-light">

                <p>Импортирование файла «{{ importFile ? importFile.name : '' }}» завершено</p>

            </div>

            <template #modal-footer="{ hide }">
                <b-button
                    variant="primary"
                    @click="hide()">
                    Хорошо
                </b-button>
            </template>
        </b-modal>

    </div>

</template>

<script>

import TableFilter from '@/components/TableFilter'
import DefaultTable from '@/components/Tables/Default'
import FilterSidebar from "@/components/FilterSidebar"

function initialFilterData() {
    return {
        number: '',
        date: ['', ''],
        organization_id: '',
        provider_id: '',
        contractor_id: '',
        work_agreement_id: '',
        work_agreement_date: ['', ''],
        object_id: '',
        sub_object_id: ''
    }
}

export default {
    name: "ConsignmentNotesList",
    components: {
        TableFilter,
        DefaultTable,
        FilterSidebar
    },
    computed: {
        consignmentNotes () {
            return this.$store.state.consignmentNotes.consignmentNotes
        },
        consignmentNotesFieldsFiltered () {
            let res = []
            this.consignmentNotesFields.forEach(item => {
                if (this.columns.filter(col => col === item.label).length) {
                    res.push(item)
                }
            })
            return res.length > 0 ? res : this.consignmentNotesFields
        }
    },
    data: () => ({
        consignmentNotesFields: [
            {
                key: 'number',
                sortable: true,
                label: '№ накладной',
                full: 'Номер накладной',
                openDefault: true
            },
            {
                key: 'date',
                sortable: true,
                label: 'Дата',
                full: 'Дата',
                openDefault: true
            },
            {
                key: 'organization',
                nesting: 'organization_id',
                sortable: true,
                label: 'Филиал заказчик',
                full: 'Филиал заказчик',
                openDefault: true
            },
            {
                key: 'provider',
                sortable: true,
                label: 'Поставщик',
                full: 'Поставщик',
                openDefault: true
            },
            {
                key: 'contractor',
                sortable: true,
                label: 'Подрядчик',
                full: 'Подрядчик',
                openDefault: true
            },
            {
                key: 'provider_contract_number',
                sortable: true,
                label: '№ договора поставки',
                full: 'Номер договора поставки',
                openDefault: true
            },
            {
                key: 'provider_contract_date',
                sortable: true,
                label: 'Дата договора поставки',
                full: 'Дата договора поставки',
                openDefault: true
            },
            {
                key: 'work_agreement_number',
                sortable: true,
                label: '№ договора подряда',
                full: 'Номер договора подряда',
                openDefault: false
            },
            {
                key: 'work_agreement_date',
                sortable: true,
                label: 'Дата договора подряда',
                full: 'Дата',
                openDefault: false
            },
            {
                key: 'object',
                nesting: 'customer_object',
                sortable: true,
                label: 'Объект',
                full: 'Объект',
                openDefault: true
            },
            {
                key: 'sub_object',
                nesting: 'customer_sub_object',
                sortable: true,
                label: 'Подобъект',
                full: 'Подобъект',
                openDefault: true
            },
            {
                key: 'responsible_full_name',
                sortable: true,
                label: 'Ответственный',
                full: 'Ответственный',
                openDefault: false
            },
            {
                key: 'responsible_phone',
                sortable: true,
                label: 'Телефон',
                full: 'Телефон',
                openDefault: false
            },
            {
                key: 'comment',
                sortable: true,
                label: 'Комм.',
                full: 'Комментарий',
                openDefault: false
            }
        ],
        importFile: null,
        columns: [],
        filter: '',
        loading: false,
        filterData: initialFilterData(),
        breadcrumbs: [
            { title: 'Главная', link: '/' },
            { title: 'Заказ ПО', link: '/' },
            { title: 'Перечень товарных накладных по подряду' }
        ],
        sortingData: {
            sort_field: '',
            sort_order: '',
            page: '',
            per_page: 25
        }
    }),
    watch: {
        importFile () {
            console.log(this.importFile)
        }
    },
    methods: {
        async getData() {
            this.loading = true
            try {
                await this.$store.dispatch('getConsignmentNotes')
            } finally {
                this.loading = false
            }
            
        },
        sendImport () {
            if (this.importFile !== null) {
                this.$bvModal.show('import-success')
                this.$bvModal.hide('import')
            } else {
                this.$bvToast.toast('Вам необходимо загрузить файл формата XML', {
                    title: 'Вы не прикрепили файл для импорта',
                    toaster: 'b-toaster-top-right',
                    solid: true,
                    appendToast: false,
                    variant: 'danger'
                })
            }
        },
        clearFilterData () {
            this.filterData = initialFilterData()
            this.getData()
        },
        async updateDataWithFilters (clearSort) {
            this.loading = true
            if (clearSort) {
                this.sortingData.sort_field = ''
                this.sortingData.sort_order = ''
                this.sortingData.page = ''
            }
            await this.$store.dispatch('getConsignmentNotes', {
                ...this.filterData,
                ...this.sortingData
            })
            this.loading = false
        },
        sorting (event) {
            this.sortingData.sort_field = event.sortBy
            this.sortingData.sort_order = event.sortDesc ? 'desc' : 'asc'
            this.updateDataWithFilters()
        },
        paginationChange (page) {
            this.sortingData.page = page
            this.updateDataWithFilters()
        },
        perPageChange (perPage) {
            this.sortingData.page = ''
            this.sortingData.per_page = perPage
            this.updateDataWithFilters()
        }
    },
    mounted () {
        this.getData()
        this.consignmentNotesFields.forEach(item => {
            if (item.openDefault) {
                this.columns.push(item.label)
            }
        })
    }
}
</script>

<style scoped>

</style>
